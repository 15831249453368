exports.onClientEntry = function onClientEntry() {
  window.addEventListener(
    'scroll',
    () => {
      if (window.scrollY > 0) {
        document.body.classList.add('isScrolled');
      } else {
        document.body.classList.remove('isScrolled');
      }
    },
    { passive: true },
  );

  window.addEventListener(
    'touchstart',
    () => {
      window.sessionStorage.setItem('hasTouch', 'true');
    },
    { passive: true },
  );

  const params = new URLSearchParams(window.location.search);
  const promoCode = params.get('promoCode');

  if (promoCode) {
    window.localStorage.setItem('promoCode', promoCode);
  }
};
